import React from "react"
import ImageInPage from "../../wal-common/src/components/Image/ImageInPage/ImageInPage"
import Title from "../Title/Title"
import HTMLContent from "../../wal-common/src/components/HTMLContent/HTMLContent"

function StandartPage({file, imageCopyright, title, html}) {
  return <>
    {file &&
      <ImageInPage file={file} width="is-one-third" copyright={imageCopyright} />
    }
    <Title level={2}>{title}</Title>
    <HTMLContent html={html}/>
  </>
}

export default StandartPage
