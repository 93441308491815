import { graphql } from "gatsby"
import React from 'react'
import Layout from "../components/layout"
import StandartPage from "../components/StandartPage/StandartPage"

const StandartPageTmp = ({data: { markdownRemark: {htmlAst, frontmatter: {image, title, imageCopyright}}}}) => (
  <Layout>
    <StandartPage file={image} title={title} html={htmlAst} imageCopyright={imageCopyright} />
  </Layout>
)

export default StandartPageTmp

export const pageQuery = graphql`
  query StandartPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        image
        imageCopyright
        title
      }
    }
  }
`

